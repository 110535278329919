const MatchStatusTypeEnum = {
  TBD: 0,
  NS: 1,
  "1H": 2,
  HT: 3,
  "2H": 4,
  ET: 5,
  BT: 6,
  P: 7,
  SUSP: 8,
  INT: 9,
  FT: 10,
  AET: 11,
  PEN: 12,
  PST: 13,
  CANC: 14,
  ABD: 15,
  AWD: 16,
  WO: 17,
  LIVE: 18,
};
export default MatchStatusTypeEnum;
