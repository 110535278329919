import { notification, message } from "antd";
import { MediaTypeEnum } from "./enums";

const isNullOrEmpty = (text) =>
  text === undefined || text === "" || text === null;

const isNumber = (value) => matchRegexp(value, /^\d+$/);

const isFloat = (value) => {
  const isFloatx = matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\.\d+)?$/i);
  return isFloatx;
};
const isEmail = (text) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(text);
};

const isPhoneNumber = (text) => {
  const phoneRegex = /^(?:\+?90)?[1-9]\d{9}$/;
  return phoneRegex.test(text);
};

const matchRegexp = (value, regexp) => {
  const validationRegexp =
    regexp instanceof RegExp ? regexp : new RegExp(regexp);
  return validationRegexp.test(value);
};

const notificationSuccess = (message) => {
  notification.success({
    message: "İşlem Başarılı",
    description: message,
  });
};
const notificationError = (message) => {
  notification.error({
    message: "İşlem Başarısız",
    description: message,
  });
};
const notificationWarning = (message) => {
  notification.warning({
    message: "Uyarı",
    description: message,
  });
};

const messageSuccess = (val) => {
  message.success(val);
};
const messageError = (val) => {
  message.error(val);
};
const messageWarning = (val) => {
  message.warning(val);
};

const isImageOrVideo = (url) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm'];
  const extension = url?.split('.').pop().toLowerCase();
  if (imageExtensions.includes(extension)) {
    return MediaTypeEnum.Image;
  } else if (videoExtensions.includes(extension)) {
    return MediaTypeEnum.Reels;
  } else {
    return 'unknown';
  }
}

const generateRandomString = (length = 15) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const toUpper = (text) => text?.toLocaleUpperCase("en-US");

const hasAccess = (action) => {
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  if (global.roles !== null) return global?.roles?.has(action);
  else return false;
};

export default {
  messageWarning,
  messageError,
  messageSuccess,
  notificationError,
  notificationSuccess,
  notificationWarning,
  hasAccess,
  isImageOrVideo,
  isNullOrEmpty,
  generateRandomString,
  isNumber,
  isFloat,
  isEmail,
  isPhoneNumber,
  matchRegexp,
  toUpper,
};
