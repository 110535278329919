const RoleActionEnum = {
    Residential: 100,
    ResidentialList: 101,
    ResidentialCreate: 102,
    ResidentialUpdate: 103,
    ResidentialDelete: 104,

    RoomType: 150,
    RoomTypeList: 151,
    RoomTypeCreate: 152,
    RoomTypeUpdate: 153,
    RoomTypeDelete: 154,

    PaymentType: 200,
    PaymentTypeList: 201,
    PaymentTypeCreate: 202,
    PaymentTypeUpdate: 203,
    PaymentTypeDelete: 204,

    FinancialType: 250,
    FinancialTypeList: 251,
    FinancialTypeCreate: 252,
    FinancialTypeUpdate: 253,
    FinancialTypeDelete: 254,

    Reservation: 300,
    ReservationList: 301,
    ReservationCreate: 302,
    ReservationUpdate: 303,
    ReservationDelete: 304,

    Customer: 350,
    CustomerList: 351,
    CustomerCreate: 352,
    CustomerUpdate: 353,
    CustomerDelete: 354,

    User: 400,
    UserList: 401,
    UserCreate: 402,
    UserUpdate: 403,
    UserDelete: 404,

    SettingsUpdate: 453,

    Financial: 500,
    FinancialList: 501,
    FinancialCreate: 502,
    FinancialUpdate: 503,
    FinancialDelete: 504,

    Report: 550,
    ReportList: 551
}

export default RoleActionEnum