import { useEffect, useState } from "react";
import { AUTH_TOKEN } from "redux/constants/Auth";
import i18next from "i18next";
import ExampleService from "services/ExampleService";
import axios from "axios";
import general from "constants/general";
import Loading from "components/shared-components/Loading";

const LoadApp = ({ onLoad }) => {
  const [loading, setLoading] = useState(true);
  const [isServerClose, setIsServerClose] = useState(false);
  const getLangInfo = async () => {
    try {
      let getLangId = localStorage.getItem("languageId");
      if (!getLangId) {
        getLangId = "81ab7132-45a3-486e-ade1-dc15fe9f8210";
        localStorage.setItem("languageId", getLangId);
        window.location.reload();
      }
      const responseLang = await axios.post(
        "https://api.varonline.app/language/dropdown"
      );
      let defaultItems = null;
      if (getLangId)
        defaultItems = responseLang?.data?.data.filter(
          (item) => item?.id == getLangId
        );
      else
        defaultItems = responseLang?.data?.data.filter(
          (item) => item?.isDefault
        );
      const responseDataTranslate = await axios.post(
        "https://api.varonline.app/dataTranslate/dropdown/" +
          defaultItems[0]?.id
      );
      var list = responseDataTranslate?.data?.data?.map((item) => {
        return `"${item.keyword}" : "${(item.value + "").replace(
          /"/g,
          '\\"'
        )}"`;
      });
      var translateData = JSON.parse(
        '{"' +
          defaultItems[0]?.shortName +
          '"' +
          ': {"translation": {' +
          list.join(",") +
          "}}} "
      );
      i18next.init(
        {
          lng: defaultItems[0]?.shortName,
          debug: false,
          resources: translateData,
          keySeparator: true,
        },
        (error) => {
          if(error) console.log(error);
        }
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getHealthCheck = async () => {
    try {
      const responseHealth = await axios.get(
        "https://api.varonline.app/health"
      );
      return responseHealth?.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const load = async () => {
    const health = await getHealthCheck();
    if (!general.isNullOrEmpty(health)) {
      onLoad(true)
      getLangInfo();
      const jwtToken = localStorage.getItem(AUTH_TOKEN);
      if (jwtToken) {
        ExampleService.getPost("auth/userinfo", null)
          .then((resp) => {
            localStorage.setItem("userinfo", JSON.stringify(resp.data?.user));
          })
          .catch((e) => {
            if (e) console.log(e);
          });
      } else {
        if (window.location?.pathname != "/auth/login") {
          window.location.href = "/auth/login";
        }
      }
    } else {
      onLoad(false)
      setLoading(false);
      setIsServerClose(true);
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) return <Loading />;
  if (isServerClose)
    return (
      <div>
        <h1>Server Kapalı</h1>
      </div>
    );
  return null;
};

export default LoadApp;
