import { DashboardOutlined, UserAddOutlined, FileImageOutlined,ThunderboltOutlined,OrderedListOutlined, DiffOutlined, BellOutlined, StockOutlined, FileSearchOutlined, TagsOutlined, UserOutlined, GlobalOutlined, TranslationOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
}]

const posts = [{
  key: 'posts',
  path: `${APP_PREFIX_PATH}/posts`,
  title: 'posts',
  icon: FileImageOutlined,
  breadcrumb: false,
  submenu: []
}]

const users = [{
  key: 'users',
  path: `${APP_PREFIX_PATH}/users`,
  title: 'users',
  icon: UserAddOutlined,
  breadcrumb: false,
  submenu: []
}]

const customers = [{
  key: 'customers',
  path: `${APP_PREFIX_PATH}/customers`,
  title: 'customers',
  icon: UserOutlined,
  breadcrumb: false,
  submenu: []
}]

const leagues = [{
  key: 'leagues',
  path: `${APP_PREFIX_PATH}/leagues`,
  title: 'leagues',
  icon: OrderedListOutlined,
  breadcrumb: false,
  submenu: []
}]

const matches = [{
  key: 'matches',
  path: `${APP_PREFIX_PATH}/matches`,
  title: 'matches',
  icon: ThunderboltOutlined,
  breadcrumb: false,
  submenu: []
}]


const varTeams = [{
  key: 'varTeams',
  path: `${APP_PREFIX_PATH}/varTeams`,
  title: 'varTeams',
  icon: StockOutlined,
  breadcrumb: false,
  submenu: []
}]

const varReferee = [{
  key: 'varReferee',
  path: `${APP_PREFIX_PATH}/varReferee`,
  title: 'varReferee',
  icon: StockOutlined,
  breadcrumb: false,
  submenu: []
}]

const announcements = [{
  key: 'announcements',
  path: `${APP_PREFIX_PATH}/announcements`,
  title: 'announcements',
  icon: BellOutlined,
  breadcrumb: false,
  submenu: []
}]

const logs = [{
  key: 'logs',
  path: `${APP_PREFIX_PATH}/logs`,
  title: 'logs',
  icon: FileSearchOutlined,
  breadcrumb: false,
  submenu: []
}]

const language = [{
  key: 'language',
  path: `${APP_PREFIX_PATH}/language`,
  title: 'language',
  icon: GlobalOutlined,
  breadcrumb: false,
  submenu: []
}]

const translate = [{
  key: 'translate',
  path: `${APP_PREFIX_PATH}/translate`,
  title: 'translate',
  icon: TranslationOutlined,
  breadcrumb: false,
  submenu: []
}]

const contact = [{
  key: 'contact',
  path: `${APP_PREFIX_PATH}/contact`,
  title: 'contact',
  icon: DiffOutlined,
  breadcrumb: false,
  submenu: []
}]

let navigationConfig = [
  ...dashBoardNavTree
]

navigationConfig.push(...customers);
navigationConfig.push(...matches);
navigationConfig.push(...varTeams);
navigationConfig.push(...varReferee);
navigationConfig.push(...leagues);
navigationConfig.push(...posts);
navigationConfig.push(...users);
navigationConfig.push(...announcements);
navigationConfig.push(...contact);
navigationConfig.push(...logs);
navigationConfig.push(...language);
navigationConfig.push(...translate);

export default navigationConfig;